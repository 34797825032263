exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analyze-results-js": () => import("./../../../src/pages/analyze-results.js" /* webpackChunkName: "component---src-pages-analyze-results-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-email-js": () => import("./../../../src/pages/email.js" /* webpackChunkName: "component---src-pages-email-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-results-step-1-js": () => import("./../../../src/pages/results-step-1.js" /* webpackChunkName: "component---src-pages-results-step-1-js" */),
  "component---src-pages-results-step-2-js": () => import("./../../../src/pages/results-step-2.js" /* webpackChunkName: "component---src-pages-results-step-2-js" */),
  "component---src-pages-results-step-3-js": () => import("./../../../src/pages/results-step-3.js" /* webpackChunkName: "component---src-pages-results-step-3-js" */),
  "component---src-pages-results-step-4-js": () => import("./../../../src/pages/results-step-4.js" /* webpackChunkName: "component---src-pages-results-step-4-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-survey-step-js": () => import("./../../../src/templates/Survey/Step.js" /* webpackChunkName: "component---src-templates-survey-step-js" */)
}

